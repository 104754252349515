import Bugsnag from '@bugsnag/js';
import { get } from 'lodash';
import { createAPIService } from './api.service';
import errors, { createAPIError } from './errors';

export const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

const service = createAPIService('/services/patient/v2/processes/');

const runningStatus = {
  started: 'STARTED',
  notStarted: 'NOT_STARTED',
  finish: 'FINISHED',
};

const pollRequest = async processId => {
  try {
    return await service.get(processId);
  } catch (e) {
    return e;
  }
};

const pollRequestAsync = async processId => {
  const res = await pollRequest(processId);

  if (res && res.data && res.data.runningStatus !== runningStatus.finish) {
    await delay(2000);
    return await pollRequestAsync(processId);
  }

  return res;
};

export const poller = async (
  processId,
  initialDelay,
  period,
  pollAttempts = 30
) => {
  await delay(initialDelay);
  let i = 0;
  while (i < pollAttempts) {
    const res = await pollRequestAsync(processId);

    if (get(res, 'data.enrollment.benefit', false)) {
      return res;
    }
    if (res.data.error || !pollAttempts) {
      Bugsnag.leaveBreadcrumb('Enrolment failed due to', res);
      return res;
    }
    let remainingAttempts = pollAttempts - i;
    Bugsnag.notify(
      `enrolment polling failed - ${remainingAttempts} retries remaining`
    );
    await delay(period);
    i += 1;
  }
  Bugsnag.notify(e => e.addMetadata(`No more attempts left...`));
  createAPIError(errors.ENROLLMENT_FAILED);
};
