import env from 'runtime.env';
const path = env.RT_S3_ASSETS;

export default `
html {
  font-size: 62.5%; /* 62.5% of 16px = 10px */
}

@font-face {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  src: url("${path}/fonts/ProximaNova.woff2") format('woff2'),
       url("${path}/fonts/ProximaNova.woff") format('woff');
}

@font-face {
  font-family: 'Proxima Nova SemiBold';
  font-style: normal;
  font-weight: 600;
  src: url("${path}/fonts/ProximaNovaSemibold.woff2") format('woff2'),
       url("${path}/fonts/ProximaNovaSemibold.woff") format('woff');
}

@font-face {
  font-family: 'Proxima Nova Bold';
  font-style: normal;
  font-weight: 700;
  src: url("${path}/fonts/ProximaNovaBold.woff2") format('woff2'),
       url("${path}/fonts/ProximaNovaBold.woff") format('woff');
}
`;
